import * as React from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';
import App from './pages/App';

const element = document.getElementById('root')

if (element) {
    const root = ReactDOM.createRoot(element)

    if (root) {
        root.render(<App/>)
    }
}
import { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { AuthFailed } from "../../exceptions"
import { randomIntFromInterval } from "../../helpers/random"
import { getAccessToken } from "../../services/auth"

export function Redirector() {

    const [checkText, setCheckText] = useState('CHECKING ACCESS PROTOCOL...')
    const [textIndex, setTextIndex] = useState(-1)
    const [checkState, setCheckState] = useState(false)
    const [authCode, setAuthCode] = useState('')
    const [queryParams, setQueryParams] = useSearchParams()
    const [isLogin, setIsLogin] = useState(false)

    // useEffect(() => {

    //     // setTimeout(async () => {
    //     //     const nextTextIndex = textIndex + 1
    //     //     const selectedChar = checkText.at(nextTextIndex)
    //     //     if (selectedChar) {
    //     //         const newText = checkText.replace(selectedChar, selectedChar?.toUpperCase())
    //     //         setCheckText(newText)
    //     //         setTextIndex(nextTextIndex)
    //     //     }

    //     //     const accessToken = localStorage.getItem("access-token")
    //     //     if (accessToken) {
    //     //         window.location.replace('/dashboard')
    //     //     } else {
    //     //         window.location.replace('/login')
    //     //     }

    //     // }, 60)

    // })

    useEffect(() => {
        const authCode = queryParams.get('code')
        if (authCode) {
            handleAuthCode(authCode)
        } else {
            const accessToken = localStorage.getItem("access-token")
            if (accessToken) {
                window.location.replace('/dashboard')
            } else {
                window.location.replace('/login')
            }
        }
    }, [queryParams])

    const handleAuthCode = async (authCode: string) => {
        try {
            const accessToken = await getAccessToken(authCode)
            window.localStorage.setItem('access-token', accessToken)
            window.location.replace('/dashboard')
        } catch (e) {
            console.log(e)
            if (e instanceof AuthFailed) {
                console.log(e.message)
                setCheckText(e.message)
            } else {
                setCheckText("Something went wrong")
            }
        }
    }

    return <div className="w-[100vw] h-[100vh] bg-slate-900 flex flex-col justify-center items-center">
        <p className="text-3xl text-white antialiased font-semibold tracking-widest">{checkText}</p>
    </div>

}
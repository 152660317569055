import { useState } from "react"
import { Button } from "../../../components/shared/Design/Button"
import { Input } from "../../../components/shared/Design/Input"
import { login } from "../../../services/auth"

function LoginCard() {

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState('')

    const handlePassword = (password: string) => {
        setPassword(password)
    }

    const handleEmail = (email: string) => {
        setEmail(email)
    }

    const handleLogin = async () => {

        setError('')

        const currentEmail = email;
        const currentPassword = password;

        if (currentEmail && currentPassword) {
            try {
                const authCode = await login(currentEmail, currentPassword)
                if (authCode) {
                    window.location.replace(`/?code=${authCode}`)
                } else {
                    setError('Invalid email or password')
                }
            } catch (e) {
                setError('Invalid email or password')
            }
        } else {
            setError('Please enter valid email and password')
        }
    }

    return <div className="bg-white rounded-md py-10 px-8 w-[28vw] min-h-[15rem] h-[40vh]">
        <p className="text-xl font font-primary">Nice to see you again!</p>
        <Input labelText="Email" placeholderText="Email or phone number" onTextChange={handleEmail} type='email' value={email} />
        <Input labelText="Password" placeholderText="Enter Password" onTextChange={handlePassword} type='password' value={password} />
        <div className="text-center mb-2">
            <span className="text-red-500 text-sm">{error}</span>
        </div>
        <Button backgroundColor="#007AFF" textColor="white" onClick={handleLogin}>Login</Button>
    </div>
}

export {
    LoginCard
}
import { AxiosError } from "axios"
import { authServiceAxios } from "."
import { AuthFailed, ResponseNotFound } from "../exceptions"

interface GeneralResponse {
    data: any,
    status: 'success' | 'error'
}

interface AccessTokenResponse extends GeneralResponse {
    success: {
        access_token: string
    }
}

async function login(email: string, password: string): Promise<string> {

    try {
        const response = await authServiceAxios.post('/store/user/login', {
            email, password
        })

        if (response && response.status == 202) {
            const responseData: GeneralResponse = response.data
            if (!responseData) {
                throw new ResponseNotFound()
            } else {
                if (responseData.status == 'error') {
                    throw new AuthFailed('Invalid email or password')
                }
                const authCode = responseData.data['auth_code']
                return authCode
            }
        } else {
            throw new ResponseNotFound()
        }
    } catch (e) {
        if (e instanceof AxiosError) {
            const response: GeneralResponse = e.response?.data
            throw new AuthFailed('Invalid email or password')
        } else {
            throw new ResponseNotFound()
        }
    }

}

const refreshAccessToken = async () => {
    try{
        const response = await authServiceAxios.get('store/user/get-access-token');

        if (response) {
            const responseData = response.data

            const accessToken = responseData?.data?.access_token

            if(window && window !== null) {
                window.localStorage.setItem('access-token', accessToken)
                 return true
            } else {
                throw new ResponseNotFound()
            }            
            
        } else {
            throw new ResponseNotFound()
        }
    } catch (e) {
        console.log('Error in refreshAccessToken', e);
        if(e instanceof AxiosError){
            const errorCode = e.response?.status;

            if(errorCode === 400){
                window.location.replace('/login')
            }
        }
        throw new ResponseNotFound()
    }
}

async function getAccessToken(code: string): Promise<string> {
    try {
        const response = await authServiceAxios.post('/store/user/generate-token', {
            auth_code: code
        }, {
            headers: {
                'x-client-host': '127.0.0.1',
                'x-client-location': 'Bengaluru'
            }
        })

        if (response && response.status == 201) {
            const responseData: AccessTokenResponse = response.data
            if (!responseData) {
                throw new ResponseNotFound()
            } else {
                if (responseData.success?.access_token) {
                    return responseData.success.access_token
                } else {
                    throw new AuthFailed(responseData.data.error)
                }
            }
        } else {
            throw new ResponseNotFound()
        }
    } catch (e) {
        if (e instanceof AxiosError) {
            const response: AccessTokenResponse = e.response?.data
            throw new AuthFailed(response.data.error)
        } else {
            throw new ResponseNotFound()
        }
    }
}


export { login, getAccessToken, refreshAccessToken }
interface ButtonProps {
    backgroundColor: string
    textColor: string
    children: string
    onClick: () => void
}

function Button(props: ButtonProps) {
    return <div className="w-[100%] ">
        <button className={`bg-[${props.backgroundColor}] text-${props.textColor} py-1 rounded-md w-[100%] h-[40%]`} onClick={props.onClick} >{props.children}</button>
    </div>
}

export {
    Button
}
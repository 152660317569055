import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";
import { Redirector } from "./components/shared/Redirector";
import Dashboard from "./pages/Dashboard";
import OrderDetails from "./pages/Dashboard/orderDetails";
import Login from "./pages/Login";

export const Router = () => {
    return <Routes>
        <Route path="/" element={<Redirector />} ></Route>
        <Route path="/login" element={<Login />} ></Route>
        <Route path="/dashboard" element={<Dashboard />} ></Route>
        <Route path="/dashboard/order/:oid" element={<OrderDetails />} ></Route>
    </Routes>

}
import { ChangeEventHandler } from "react"

interface InputProps {
    placeholderText: string
    onTextChange: (text: string) => void
    labelText: string
    type: string
    value: string
    noLabel?: boolean
}

function Input(props: InputProps) {

    return <div className="my-3 flex flex-col">
        {
            props.noLabel ? <label className="text-[#333333] text-[0.8rem] font ml-3">{props.labelText}</label> : <div></div>
        }
        <input className="my-2 bg-[#F2F2F2] py-2 px-2 rounded-md text-[#808080] focus-visible:outline-none focus-visible:border-[#F2F2F2]" value={props.value} type={props.type} placeholder={props.placeholderText} onChange={(e) => props.onTextChange(e.target.value)} />
    </div>
}

export {
    Input
}
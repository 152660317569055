import { AxiosError } from "axios";
import { storeServiceAxios } from ".";
import { AuthFailed, ResponseNotFound } from "../exceptions";
import { refreshAccessToken } from "./auth";

export interface UserAddress {
    name: string;
    city: string;
    state: string;
    address_line_1: string;
    pincode: string
}

export interface Order {
    user_email: string;
    order_id: string;
    sku: string;
    title: string;
    description: string;
    points: number
    user_address: UserAddress;
    order_status: string;
    created: string
}

export interface PaginatedOrdersResponse {
    data: {
        links: {
            next: null | string;
            previous: null | string;
        },
        count: number;
        results: Array<Order>
    },
    status: "success" | "error"
}

export interface SearchQueryOrderResponse {
    data: {
        data: Array<Order>
    },
    status: "success" | "error"
}

interface OrderQueryParamas {
    page: number
    query?: string | undefined
}

async function getAllOrders(page: number, query?: string | undefined, retries: number = 0): Promise<PaginatedOrdersResponse | SearchQueryOrderResponse | null> {
    try {

        const queryParamas: OrderQueryParamas = {
            'page': page
        }

        if (query) {
            queryParamas['query'] = query
        }

        const accessToken = window.localStorage.getItem('access-token') || ""
        const response = await storeServiceAxios.get('/client/orders', {
            headers: {
                'access-token': accessToken,
            },
            params: queryParamas
        })
        if (response && response.status == 200) {
            if (query) {
                return response.data as SearchQueryOrderResponse
            } else {
                return response.data as PaginatedOrdersResponse
            }
        } else {
            return null
        }
    } catch (e) {
        if (e instanceof AxiosError) {
            if (e.response?.status == 401) {
                if (retries >= 3) {
                    if (window && window !== null) {
                        window.location.replace('/login')
                    }
                    throw new AuthFailed('refresh token invalid')
                } else {
                    await refreshAccessToken()
                    return await getAllOrders(page, query, retries++)
                }
            } else {
                throw new ResponseNotFound()
            }
        } else {
            throw new ResponseNotFound()
        }
    }
}

const getOrderDetails = async (oid: string, retries: number = 0): Promise<any | null> => {
    try {
        const accessToken = window.localStorage.getItem('access-token') || ""
        const response = await storeServiceAxios.get('/client/user/order/' + oid, {
            headers: {
                'access-token': accessToken
            }
        })

        if (response && response.status == 200) {
            const responseData = response.data
            return responseData
        } else {
            return null
        }
    } catch (e: any) {
        if (e instanceof AxiosError) {
            if (e.response?.status == 401) {
                if (retries >= 3) {
                    if (window && window !== null) {
                        window.location.replace('/login')
                    }
                    throw new AuthFailed('refresh token invalid')
                } else {
                    await refreshAccessToken()
                    return await getOrderDetails(oid, retries++)
                }
            } else {
                throw new ResponseNotFound()
            }
        } else {
            throw new ResponseNotFound()
        }
    }
}

export {
    getAllOrders, getOrderDetails
}
import React, { useEffect, useState } from 'react'
import { Orders } from '../../components/Orders'
import { Navbar } from './components/Navbar'
import { Sidebar } from './components/Sidebar'
import { Title } from './components/Title'

function Dashboard() {

    const [search, setSearch] = useState<string>('')

    const handleSearchInput = (newSearch: string) => {
        setSearch(newSearch)
    }

    return <div className='h-[100vh] w-[100vw] flex flex-row bg-[#f8f9fd]'>
        <Sidebar></Sidebar>
        <section>
            <Navbar onSearchChange={handleSearchInput} searchText={search} />
            <Title text='Orders' />
            <Orders search={search}></Orders>
        </section>
    </div>
}

export default Dashboard
import { faCoins, faFileArrowDown } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { getOrderDetails } from "../../../../services/orders"

export const OrderData = (props: { oid: string }) => {

  const [orderData, setOrderData] = useState<{
    docket: {
      assigned: boolean,
      docket_no: string,
      shipping_company: string
    },
    order_id: string,
    order_status: "pending" | 'processing' | 'dispatched' | 'delivered' | 'cancelled' | 'returned',
    user_address: {
      name: string,
      phone: string,
      alternate_phone_number?: string,
      address_type: string,
      address_line_1: string,
      landmark: string,
      city: string,
      state: string,
      pincode: string,
    },
    user_email: string
    points: number,
    sku: string,
    title: string,
    image: string,
    brand: string,
    description: string
  } | null>(null);
  const [error, setError] = useState({ status: false, message: '' });
  const navigate = useNavigate()
  useEffect(() => {
    if (props.oid) {
      getOrderDetails(props.oid).then(res => {
        console.log('Get Order Details Res:', res);
        const response = res?.data?.success || null;
        setOrderData(response);
      }).catch((error) => {
        console.log('Get Order Details Error:', error?.response?.data);
        setError({ status: true, message: 'Something Went Wrong. Please Try again' });
      })
    }
  }, [props.oid])

  return (
    <div className="w-full p-10 space-y-8">
      {(orderData !== null) && (!error?.status) ? <>
        <div className="w-full bg-[#ffffff] p-6 rounded">
          <div className="w-full grid grid-cols-2 gap-6">
            <div className="space-y-4">
              <h2 className="text-xl font-medium">Delivery Address</h2>
              <h3 className="text-lg text-[#1e1e1e] font-medium">{orderData?.user_address?.name || 'User Name not Found'}, <span className="text-sm font-light text-[#1e1e1e]/75">{orderData?.user_address?.phone}, {orderData?.user_address?.alternate_phone_number || ''}</span></h3>
              <h4 className="capitalize font-medium">{orderData?.user_address?.address_type}</h4>
              <p className="font-light text-[#1e1e1e]/75">{`${orderData?.user_address?.address_line_1}, ${orderData?.user_address?.landmark}, ${orderData?.user_address?.city}, ${orderData?.user_address?.state} - ${orderData?.user_address?.pincode}`}</p>
            </div>
            <div className={`space-y-4 ${orderData?.order_status === 'delivered' ? 'opacity-100 pointer-events-auto' : 'opacity-50 pointer-events-none'}`}>
              <h2 className="text-xl font-medium">Bills</h2>
              <div className="flex items-center space-x-4">
                <FontAwesomeIcon icon={faFileArrowDown} color="#fd7004" />
                <span>Download Invoice</span>
              </div>
              <button className="text-sm font-medium py-2 px-10 text-[#0064FF] shadow hovershadow-md rounded">DOWNLOAD</button>
            </div>
          </div>
        </div>
        <div className="w-full bg-[#ffffff] p-6 rounded">
          <div className="grid grid-cols-2 gap-6 min-h-[10rem]">
            <div className="space-y-6">
              <h2 className="text-xl font-medium">Docket Info.</h2>
              {orderData.docket.assigned ? <div className="grid grid-cols-2 gap-4">
                <h3 className="font-light text-[#1e1e1e]/75">Docket No.</h3>
                <h3 className="font-medium text-[#1e1e1e]">{orderData?.docket?.docket_no ?? "N/A"}</h3>
                <h3 className="font-light text-[#1e1e1e]/75">Assigned TO</h3>
                <h3 className="font-medium text-[#1e1e1e]">{orderData?.docket?.shipping_company?.toUpperCase().replaceAll("-", " ") ?? "N/A"}</h3>
                <h3 className="font-light text-[#1e1e1e]/75">Status</h3>
                <h3 className="capitalize font-medium text-[#1e1e1e]">{orderData.order_status}</h3>
              </div> : <div className="w-full flex items-center justify-center">
                <h3 className="text-lg font-medium">Docket not yet assigned!</h3></div>}
            </div>

            <div className="space-y-6">
              <h2 className="text-xl font-medium">Product Info.</h2>
              <div className="w-full flex space-x-6">

                {/* Image */}
                <div className="w-[7.5rem]">
                  <img width="100%" height="100%" src={orderData?.image} />
                </div>

                {/* Details */}
                <div className="w-[calc(100%-7.5rem-1.5rem)] space-y-4">
                  <h3 className="text-sm font-light text-[#1e1e1e]/75">{orderData?.sku}</h3>
                  <h1 className="font-medium capitalize">{orderData?.title || ''}</h1>
                  <div className="flex space-x-4 items-center">
                    <FontAwesomeIcon icon={faCoins} color="#FFD700" />
                    <span>{orderData?.points}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div></> : <></>}
      {error.status ? <div className="bg-white rounded w-full space-y-8 min-h-[17.5rem] flex flex-col items-center justify-center">
        <h3 className="text-lg font-medium">{error?.message} !</h3>
        <div className="flex items-center space-x-10">
          <button onClick={() => window?.location?.reload()} className="text-sm font-medium py-2 px-10 text-[#0064FF] shadow hovershadow-md rounded">Try Again</button>
          <button onClick={() => navigate('/login')} className="text-sm font-medium py-2 px-10 text-[#0064FF] shadow hovershadow-md rounded">Login</button>
        </div>
      </div> : <></>}
    </div>
  )
}
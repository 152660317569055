import { useParams } from "react-router-dom"
import { Navbar } from "../components/Navbar"
import { Sidebar } from "../components/Sidebar"
import { Title } from "../components/Title"
import { OrderData } from "./components/orderData"

const OrderDetails = () => {

  const { oid } = useParams()

  console.log(oid);


  return <div className='h-[100vh] w-[100vw] flex flex-row bg-[#f8f9fd]'>
    <Sidebar />
    <section>
      {
        !oid ? <Navbar onSearchChange={(search) => { }} searchText='' /> : <></>
      }
      {oid ? <OrderData oid={oid} /> : <div className="w-full bg-white my-6 min-h-[20rem] flex flex-col item-center justify-center">
        <h3>Invalid Order Number</h3>
      </div>}
    </section>
  </div>
}

export default OrderDetails
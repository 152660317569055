interface TitleProps {
    text: string
}

function Title(props: TitleProps) {
    return <h1 className="text-2xl font-bold font font-primary mx-10 mt-6 mb-2">{props.text}</h1>
}

export {
    Title
}
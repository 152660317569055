import React from 'react'
import { LoginCard } from './components/LoginCard'
import logo from '../../assets/logo.png'

function Login() {
    return <div className='bg-[#FCE5D6] py-[20vh] px-[10vw] w-[100vw] h-[100vh] flex flex-row justify-between'>
        {/* Left Side */}
        <div className='w-[40vw] h-[20vh] flex flex-col items-center'>
            <div className='w-[50%] mx-auto'><img width="100%" height="100%" src={logo} alt='accoladez logo'></img></div>
            <p className='text-[#808080] font-light text-lg my-6'>Engage Beyond Transactions</p>
        </div>
        {/* Right Side */}
        <div>
            <LoginCard></LoginCard>
        </div>
    </div>
}

export default Login
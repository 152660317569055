import { useEffect, useState } from "react"
import { getAllOrders, Order, PaginatedOrdersResponse, SearchQueryOrderResponse } from "../services/orders"

import { useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCoins } from "@fortawesome/free-solid-svg-icons"
import { format } from 'date-fns'

interface OrderProps {
    search: string | undefined
}

function Orders(props: OrderProps) {

    console.log(props.search)

    const [page, changePage] = useState(1)
    const [isNextPagePossible, setNextPagePossible] = useState(false)
    const [isPreviousPagePossible, setPreviousPagePossible] = useState(false)
    const [orders, setOrders] = useState<Array<Order>>([]);

    const [error, setError] = useState({ status: false, message: '' });

    const navigate = useNavigate()

    useEffect(() => {
        getOrders()
    }, [page, props.search])

    const getOrders = async () => {
        setOrders([])
        await getAllOrders(page, props.search).then(response => {
            if (response && response != null) {

                setPreviousPagePossible(false)
                setNextPagePossible(false)

                if (props.search) {
                    const searchResponse = response as SearchQueryOrderResponse
                    setOrders(searchResponse.data.data)
                } else {

                    const paginatedResponse = response as PaginatedOrdersResponse

                    if (paginatedResponse.data.links.next) {
                        setNextPagePossible(true)
                    } else {
                        setNextPagePossible(false)
                    }

                    if (paginatedResponse.data.links.previous) {
                        setPreviousPagePossible(true)
                    } else {
                        setPreviousPagePossible(false)
                    }

                    setOrders(paginatedResponse.data.results)
                }

            } else {
                setError({ status: true, message: 'Something Went Wrong. Please Try Again !' })
            }
        }).catch(e => {
            setError({ status: true, message: 'Something Went Wrong. Please Try Again !' })
        })
    }

    const handleNext = () => {
        if (isNextPagePossible) {
            changePage(currentPage => {
                return currentPage + 1
            })
        }
    }

    const handlePrevious = () => {
        if (isPreviousPagePossible) {
            changePage(currentPage => {
                return currentPage - 1
            })
        }
    }

    return (
        <>
            {!error.status ? <div className="flex flex-col mx-10 h-[80%]">
                <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-4 inline-block min-w-full sm:px-6 lg:px-8">
                        <div className="overflow-hidden">
                            <table className="min-w-full text-center">
                                <thead className="border-b bg-gray-800">
                                    <tr>
                                        <th scope="col" className="text-sm font-medium text-white px-6 py-4">
                                            Order Id
                                        </th>
                                        <th scope="col" className="text-sm font-medium text-white px-6 py-4">
                                            SKU
                                        </th>
                                        <th scope="col" className="text-sm font-medium text-white px-6 py-4">
                                            Points
                                        </th>
                                        <th scope="col" className="text-sm font-medium text-white px-6 py-4">
                                            Name
                                        </th>
                                        <th scope="col" className="text-sm font-medium text-white px-6 py-4">
                                            City
                                        </th>
                                        <th scope="col" className="text-sm font-medium text-white px-6 py-4">
                                            State
                                        </th>
                                        <th scope="col" className="text-sm font-medium text-white px-6 py-4">
                                            Status
                                        </th>
                                        <th scope="col" className="text-sm font-medium text-white px-6 py-4">
                                            Order Date
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        orders.map((order, index) => {

                                            const rawOrderDate = order.created

                                            const formattedOrderDate = format(new Date(rawOrderDate), 'dd-MM-yyyy')

                                            return <tr key={index} className="bg-white border-b font font-secondry">
                                                <td onClick={() => navigate('/dashboard/order/' + order.order_id)} className="px-6 py-4 text-sm font-medium text-[#0064FF] cursor-pointer hover:underline whitespace-nowrap ">
                                                    {order.order_id}
                                                </td>
                                                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                    {order.sku}
                                                </td>
                                                <td className="text-sm text-gray-900 space-x-3 font-normal px-6 py-4 whitespace-nowrap">
                                                    <FontAwesomeIcon icon={faCoins} color="#FFD700" />
                                                    <span>{order?.points}</span>
                                                </td>
                                                <td className="text-sm text-gray-900 font-normal px-6 py-4 whitespace-nowrap">
                                                    {order.user_address.name}
                                                </td>
                                                <td className="text-sm text-gray-900 font-normal px-6 py-4 whitespace-nowrap">
                                                    {order.user_address.city}
                                                </td>
                                                <td className="text-sm text-gray-900 font-normal px-6 py-4 whitespace-nowrap">
                                                    {order.user_address.state}
                                                </td>
                                                <td className="capitalize text-sm text-gray-900 font-normal px-6 py-4 whitespace-nowrap">
                                                    {order.order_status}
                                                </td>
                                                <td className="text-sm text-gray-900 font-normal px-6 py-4 whitespace-nowrap">
                                                    {formattedOrderDate}
                                                </td>
                                            </tr>
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="flex flex-row justify-between w-[100%] mt-2">
                    <p className="font font-secondry text-[0.8rem] font-bold">{`Showing Page ${page} of ${orders.length}`}</p>
                    <div>
                        {
                            isPreviousPagePossible ? <button className="border-2 rounded-md px-3 py-1 bg-white border-gray-200 mx-2 hover:border-gray-400 transition-all duration-75" onClick={handlePrevious} disabled={page == 1 ? true : false} >Previous</button> : <div></div>
                        }
                        {
                            isNextPagePossible ? <button className="border-2 rounded-md px-3 py-1 bg-white border-gray-200 hover:border-gray-400 transition-all duration-75" onClick={handleNext}>Next</button> : <div />
                        }
                    </div>
                </div>
            </div> : <></>}
            {error.status ? <div className="bg-white rounded w-full min-h-[17.5rem] flex flex-col space-y-8 items-center justify-center">
                <h3 className="text-lg font-medium">{error?.message} </h3>
                <div className="flex items-center space-x-10">
                    <button onClick={() => window?.location?.reload()} className="text-sm font-medium py-2 px-10 text-[#0064FF] shadow hovershadow-md rounded">Try Again</button>
                    <button onClick={() => navigate('/login')} className="text-sm font-medium py-2 px-10 text-[#0064FF] shadow hovershadow-md rounded">Login</button>
                </div>
            </div> : <></>}
        </>
    )
}

export { Orders }
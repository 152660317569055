import { Input } from "../../../components/shared/Design/Input"

interface NavbarProps {
    onSearchChange: (search: string) => void
    searchText: string
}

function Navbar(props: NavbarProps) {
    return <section className="w-[calc(100vw-18vw)] bg-white h-[5rem] border-b-2">
        <div className="flex flex-col justify-evenly">
            <div className="w-[30%] ml-10">
                <Input labelText="Search" onTextChange={(e) => props.onSearchChange(e)} placeholderText="Search" type="text" value={props.searchText} />
            </div>
        </div>
    </section>
}

export {
    Navbar
}
import axios from "axios";
import { AUTH_SERVER, STORE_SERVER } from "../constants/config";

const authServiceAxios = axios.create({
    baseURL: AUTH_SERVER,
    maxRedirects: 1,
    withCredentials: true,
})

const storeServiceAxios = axios.create({
    baseURL: STORE_SERVER,
    maxRedirects: 1,
    withCredentials: true,
})

export {
    authServiceAxios,
    storeServiceAxios
}
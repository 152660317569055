import logo from '../../../assets/logo.png'

function Sidebar() {
    return <aside className="w-[18vw] h-[100vh] border-r-2" aria-label="Sidebar">
        <div className="overflow-y-auto py-4 px-3 bg-white h-[100vh]">
            <a href="/" className="flex items-center max-w-[7.5rem] pl-2.5 mb-5">
                <img src={logo} width="100%" height="100%" className="mr-3" alt="Accoladez Logo" />
            </a>
            <ul className="space-y-2">
                <li>
                    <a href="/dashboard" className="flex items-center p-2 text-base font-normal rounded-lg bg-[#fd7004]">
                        <svg aria-hidden="true" className="w-6 h-6 text-white transition duration-75" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z"></path><path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z"></path></svg>
                        <span className="ml-3 text-white">Orders</span>
                    </a>
                </li>
            </ul>
        </div>
    </aside>

}

export {
    Sidebar
}
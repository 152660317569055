class ResponseNotFound extends Error {

    constructor(message?: string) {
        super(message)
    }

}

class AuthFailed extends Error {

    constructor(message: string) {
        super(message)
    }

}

export {
    ResponseNotFound,
    AuthFailed
}